import type { FC } from 'react'

import Title from '@typography/Title'

import { DivItemWrapper, LinkItemWrapper } from './styles'

type Props = {
  label: string
  link?: string
}

export const MenuItem: FC<Props> = ({ label, link }) => {
  const titleElement = <Title level={6}>{label}</Title>

  return link ? (
    <LinkItemWrapper href={link || ''}>{titleElement}</LinkItemWrapper>
  ) : (
    <DivItemWrapper>{titleElement}</DivItemWrapper>
  )
}
